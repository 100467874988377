@use "../global" as g;
@use "../foundation" as f;

.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  overflow-y: scroll;
  z-index: 999;
  background-color: rgba(0,0,0,.4);

  .content-bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }


  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    max-width: 840px;
    width: 95%;
    height: 90svh;

    @include g.mq(md) {
      border-radius: 10px;
    }
    @include g.mq(sm) {
      border-radius: 10px;
    }

    .content-close {
      position: absolute;
      cursor: pointer;

      @include g.mq(md) {
        width: 40px;
        height: 40px;
        top: 35px;
        right: 35px;  
      }
      @include g.mq(sm) {
        width: 40px;
        height: 40px;
        top: 35px;
        right: 35px;  
      }
  
      &::before,&::after {
        content: "";
        position: absolute;
        background-color: g.$col-green;
        top: 50%;
        left: 50%;

        @include g.mq(md) {
          width: 40px;
          height: 2px;  
        }
        @include g.mq(sm) {
          width: 40px;
          height: 2px;  
        }
      }
      &::before {
        transform: translate(-50%,-50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%,-50%) rotate(-45deg);
      }
    } 

  }

  .content-box {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @include g.mq(md) {
      padding: 73px 130px;
    }
    @include g.mq(sm) {
      padding: 50px 20px;
    }

    .head {
      display: flex;
      align-items: center;

      .img {

        @include g.mq(md) {
          flex: 0 0 86px;
          margin-right: 10px;  
        }
        @include g.mq(sm) {
          flex: 0 0 80px;
          margin-right: 10px;
        }
      }
      .message {
        flex: 0 1 100%;

        .job {

          @include g.mq(md) {
            font-size: 1.4rem;
            margin-bottom: 5px;  
          }
          @include g.mq(sm) {
            font-size: 13px;
            margin-bottom: 5px;
          }
        }
        .name {
          font-weight: bold;

          @include g.mq(md) {
            font-size: 3.4rem;
          }
          @include g.mq(sm) {
            font-size: 28px;
          }

          span {
            font-weight: 400;
            letter-spacing: 0.005em;

            @include g.mq(md) {
              font-size: 1.4rem;
              padding-left: 9px;  
            }
            @include g.mq(sm) {
              display: block;
              font-size: 13px;
            }
          }
        }
      }
    }
    .body {

      @include g.mq(md) {
        margin-top: 17px;
      }
      @include g.mq(sm) {
        margin-top: 20px;
      }

      .list {

        .item {
          border-bottom: 2px dotted g.$col-green;

          @include g.mq(md) {
            padding: 25px 0;
          }
          @include g.mq(sm) {
            padding: 20px 0;
          }

          &:first-child {
            border-top: 2px dotted g.$col-green;
          }

          .title {
            position: relative;
            font-weight: bold;
            color: g.$col-green;

            @include g.mq(md) {
              font-size: 1.8rem;
              padding-left: 25px;  
            }
            @include g.mq(sm) {
              font-size: 18px;
              padding-left: 25px;
            }

            &::before {
              content:"";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              background-image: url("../img/cmn/smile-icon01.png");
              background-size: cover;
              background-repeat: no-repeat;

              @include g.mq(md) {
                width: 21px;
                height: 21px;  
              }
              @include g.mq(sm) {
                width: 21px;
                height: 21px;  
              }
            }
          }
          .txt {
            line-height: 1.5;
            font-size: 1.6rem;
            margin-top: 5px;
            padding-left: 25px;

            @include g.mq(md) {
              
            }
            @include g.mq(sm) {
              font-size: 14px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}