// global/_mixins.scss
@use "variables" as var;

/* ==========================================================================
  mixin
  ========================================================================== */
// break point
// デフォルトはmdでレイアウト切り替え
$breakpoints: (
  'sm': (max-width: 767px),
  'md': (min-width: 768px),
  'lg': (min-width: 980px),
  'xl': (min-width: 1200px),
  'xxl': (min-width: 1300px),
  'sm_landscape' : '(max-width: 812px) and (max-height: 576px) and (orientation: landscape)',
  'sm_tall' : '(max-width: 539px) and (min-height: 700px) and (max-height: 1024px) and (orientation: portrait)' ,
  'md_portrait' : '(min-width: 540px) and (max-width: 768px) and (max-height: 1024px) and (orientation: portrait)',
  'ie11' :  '(min-width: 768px) and (-ms-high-contrast: none)'
) !default;

/* --------------------------------------------------------------------------
  media query (SP（小）→ PC（大）の順に適用)
  -------------------------------------------------------------------------- */
@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @error "指定されたブレークポイントは定義されていません。" + "指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  }
}