@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
contact
========================================================================== */
#contact {

  .contact-wrap {
    max-width: 880px;
    margin: 0 auto;

    @include g.mq(md) {
      margin-top: 97px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(46);
    }

    .head {

      .head-title {
        font-weight: bold;
        text-align: center;

        @include g.mq(md) {
          line-height: 1.4;
          font-size: 3rem;  
        }
        @include g.mq(sm) {
          line-height: 1.36;
          font-size: f.px2vw(22);
        }
      }
      .head-txt {
        text-align: center;

        @include g.mq(md) {
          line-height: 2;
          margin-top: 17px;
          font-size: 1.6rem;  
        }
        @include g.mq(sm) {
          line-height: 1.625;
          font-size: f.px2vw(16);
          margin-top: f.px2vw(20);
        }
      }
      .tell {

        @include g.mq(md) {
          margin-top: 32px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(22);
        }
      }
    }
  }
}

form {

  @include g.mq(md) {
    margin-top: 70px;
  }
  @include g.mq(sm) {
    margin-top: f.px2vw(65);
    padding: 0 f.px2vw(20);
  }

  .form-title {
    font-weight: bold;

    @include g.mq(md) {
      font-size: 1.8rem;
    }
    @include g.mq(sm) {
      font-size: f.px2vw(18);
    }
  }
  .form-txt {

    @include g.mq(md) {
      line-height: 2;
      font-size: 1.6rem;
      margin-top: 15px;  
    }
    @include g.mq(sm) {
      line-height: 1.625;
      margin-top: f.px2vw(10);
      font-size: f.px2vw(16);
    }
  }
  .form-box {

    @include g.mq(md) {
      margin-top: 45px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(40);
    }

    .label-box {
      display: flex;
      align-items: center;

      @include g.mq(md) {
        margin-bottom: 10px;
      }
      @include g.mq(sm) {
        margin-bottom: f.px2vw(10);
      }

      p {
        color: #fff;
        font-weight: bold;

        @include g.mq(md) {
          font-size: 1.4rem;
          border-radius: 4px;
          padding: 5px 10px;
          margin-right: 10px;  
        }
        @include g.mq(sm) {
          font-size: f.px2vw(14);
          border-radius: f.px2vw(4);
          padding: f.px2vw(5) f.px2vw(10);
          margin-right: f.px2vw(10);  
          flex: 0 0 f.px2vw(50);
        }

        &.any {
          background-color: #B1B1B1;
        }
        &.required {
          background-color: #F04C63;
        }
      }

      label {
        font-weight: bold;

        @include g.mq(md) {
          font-size: 1.8rem;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(18);
        }

        span {

          @include g.mq(sm) {
            font-size: f.px2vw(14);
          }
        }
      }
    }
    
    .input-box {

      input {
        width: 100%;
        border: 1px solid #E0E0E0;

        @include g.mq(md) {
          padding: 20px 40px;
          height: 62px;
          border-radius: 10px;  
          font-size: 1.6rem;
        }
        @include g.mq(sm) {
          padding: f.px2vw(20) f.px2vw(25);
          height: f.px2vw(62);
          border-radius: f.px2vw(10);  
          font-size: f.px2vw(16);
        }
        
        &::placeholder {
          color: #A3A3A3;
        }
      }
      textarea {
        width: 100%;
        border: 1px solid #E0E0E0;

        @include g.mq(md) {
          padding: 20px 40px;
          height: 250px;
          border-radius: 10px;  
          font-size: 1.6rem;
        }
        @include g.mq(sm) {
          padding: f.px2vw(20) f.px2vw(25);
          border-radius: f.px2vw(10);  
          font-size: f.px2vw(16);
        }
        
        &::placeholder {
          color: #A3A3A3;
        }
      }
    }
    .remarks {

      @include g.mq(md) {
        line-height: 1.57;
        margin-top: 10px;
        font-size: 1.4rem;  
      }
      @include g.mq(sm) {
        line-height: 1.38;
        margin-top: f.px2vw(10);
        font-size: f.px2vw(13);
      }
    }

    .select-box {

      @include g.mq(md) {
        width: 50%;
      }
      @include g.mq(sm) {
        width: 100%;
      }

      select {
        width: 100%;
      }
    }

    .select-content {

      @include g.mq(md) {
        display: flex;
        align-items: center;
        column-gap: 35px;  
      }

      .select-box {
        display: flex;
        align-items: center;
        width: auto;

        @include g.mq(md) {
          column-gap: 15px;
        }
        @include g.mq(sm) {
          column-gap: f.px2vw(15);

          &+.select-box {
            margin-top: f.px2vw(10);
          }
        }

        p {
          font-weight: bold;

          @include g.mq(md) {
            font-size: 1.8rem;
          }
          @include g.mq(sm) {
            flex: 0 0 f.px2vw(60);
            font-size: f.px2vw(18);
          }
        }

        select {

          @include g.mq(md) {
            width: 130px;
          }
          @include g.mq(sm) {
            width: f.px2vw(147);
          }
        }
      }
    }

    select {
      border: 1px solid #E0E0E0;

      @include g.mq(md) {
        width: 130px;
        height: 62px;
        border-radius: 10px;
        padding: 0 30px;
        font-size: 1.8rem;  
      }
      @include g.mq(sm) {
        width: 100%;
        height: f.px2vw(62);
        border-radius: f.px2vw(10);
        padding: 0 f.px2vw(25);
        font-size: f.px2vw(16);
      }

      option {
        display: flex;
        align-items: center;
        font-weight: bold;

        @include g.mq(md) {
          padding: 20px 30px;
          font-size: 1.8rem;  
        }
        @include g.mq(sm) {
          padding: f.px2vw(20) f.px2vw(25);
          font-size: f.px2vw(16);
        }
      }
    }

    .radio-list {
      display: flex;
      flex-wrap: wrap;

      @include g.mq(md) {
        margin: 30px 0 15px;
        gap: 15px 30px  ;  
      }
      @include g.mq(sm) {
        gap: f.px2vw(18) f.px2vw(20);
        margin: f.px2vw(12) 0 f.px2vw(8);
      }

      .item {

        input {
          display: none;
          cursor: pointer;
        }

        input[type=checkbox]:checked + .radio::after {
          opacity: 1;
        }
        input[type=radio]:checked + .radio::after {
          opacity: 1;
        }
        
        .radio {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;

          @include g.mq(md) {
            font-size: 1.6rem;
            padding-left: 25px;  
          }
          @include g.mq(sm) {
            line-height: 1.5;
            font-size: f.px2vw(16);
            padding-left: f.px2vw(31);  
          }

          &::before {
            background: #fff;
            border: 1px solid #BEBEBE;
            border-radius: 50%;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include g.mq(md) {
              width: 20px;
              height: 20px;  
            }
            @include g.mq(sm) {
              width: f.px2vw(20);
              height: f.px2vw(20);  
            }
          }
          &::after {
            background: #000;
            border-radius: 50%;
            content: '';
            display: block;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include g.mq(md) {
              width: 8px;
              height: 8px; 
              left: 6px; 
            }
            @include g.mq(sm) {
              width: f.px2vw(8);
              height: f.px2vw(8);  
              left: f.px2vw(6);
            }
          }
        }
      }
    }
  }

  .form-checkbox  {

    @include g.mq(md) {
      margin-top: 45px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(40);
    }

    .checkbox-txt {

      @include g.mq(md) {
        line-height: 1.75;
        font-size: 1.6rem;  
      }
      @include g.mq(sm) {
        line-height: 1.6;
        font-size: f.px2vw(15);
      }

      a {
        text-decoration: underline;
      }
    }

    .box {
      text-align: center;
      display: flex;
      justify-content: center;

      @include g.mq(md) {
        margin-top: 55px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(25);
      }

      input {
        display: none;
      }

      input[type=checkbox]:checked + .checkbox::after {
        opacity: 1;
      }
      
      .checkbox {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        @include g.mq(md) {
          font-size: 1.6rem;
          padding-left: 25px;  
        }
        @include g.mq(sm) {
          font-size: f.px2vw(16);
          padding-left: f.px2vw(30);
        }

        &::before {
          background: #fff;
          border: 1px solid #BEBEBE;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @include g.mq(md) {
            width: 20px;
            height: 20px;  
          }
          @include g.mq(sm) {
            width: f.px2vw(20);
            height: f.px2vw(20);  
          }
        }
        &::after {
          content: '';
          display: block;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-image: url("../img/cmn/check-icon01.png");
          background-size: contain;
          background-repeat: no-repeat;

          @include g.mq(md) {
            width: 12px;
            height: 9px;
            left: 5px;  
          }
          @include g.mq(sm) {
            width: f.px2vw(12);
            height: f.px2vw(9);  
            left: f.px2vw(5);
          }
        }
      }            
    }
  }

  .form-button {

    @include g.mq(md) {
      margin-top: 45px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(30);
    }
  }
}

/*===========================================
# thanks
/*===========================================*/
#thanks {

  .thanks-wrap {

    @include g.mq(md) {
      margin-top: 115px;
    }
    @include g.mq(sm) {
      margin-top: 42px;
    }

    .title {
      font-weight: bold;

      @include g.mq(md) {
        text-align: center;
        font-size: 3rem;
      }
      @include g.mq(sm) {
        line-height: 1.36;
        font-size: f.px2vw(22);
      }
    }
    .txt {

      @include g.mq(md) {
        text-align: center;
        line-height: 2;
        font-size: 1.6rem;
        margin-top: 50px;  
      }
      @include g.mq(sm) {
        line-height: 1.625;
        margin-top: f.px2vw(22);
        font-size: f.px2vw(16);
      }
    }
    .button {

      @include g.mq(md) {
        margin-top: 46px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(38);
        padding: 0 f.px2vw(20);
      }
    }
    .clover {
      margin: 0 auto;

      @include g.mq(md) {
        margin-top: 23px;
        width: 66px;  
      }
      @include g.mq(sm) {
        width: f.px2vw(66);
        margin-top: f.px2vw(30);
      }
    }
  }

  .bg {
    
    .icon {

      @include g.mq(md) {
        top: 237px;
        right: -211px;  
      }
      @include g.mq(sm) {
        top: f.px2vw(418);
        right: f.px2vw(-421);
      }
    }
  }
}