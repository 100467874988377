@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
記事ページ
========================================================================== */
#single {

  img {
    width: auto;
    height: auto;
  }
  

  .single-wrap {
    margin: 0 auto;
    max-width: 880px;

    @include g.mq(md) {
      margin-top: 80px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(40);
    }

    .head {

      .date {
        font-weight: bold;
        color: g.$col-green;

        @include g.mq(md) {
          font-size: 1.4rem;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(14);
        }
      }
      .head-title {
        font-weight: bold;
        border-bottom: 1px solid g.$col-green;

        @include g.mq(md) {
          font-size: 3rem;
          padding-bottom: 13px;
          margin-top: 14px;  
        }
        @include g.mq(sm) {
          font-size: f.px2vw(22);
          padding-bottom: f.px2vw(10);
          margin-top: f.px2vw(15);
        }
      }      

      .mv {
        text-align: center;

        @include g.mq(md) {
          margin-top: 22px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(20);
        }

        img {

          @include g.mq(md) {
            border-radius: 10px;
          }
          @include g.mq(sm) {
            border-radius: f.px2vw(10);
          }
        }
      }
    }

    .body {

      @include g.mq(md) {
        margin-top: 26px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(20);
      }

      .button {

        @include g.mq(md) {
          margin-top: 60px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(40);
        }
      }
    }
  }
}
