@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
about
========================================================================== */
#about {

  .about-wrap {
    max-width: 850px;

    @include g.mq(md) {
      margin: 76px auto 0;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(40);
    }

    .about-txt {

      @include g.mq(md) {
        line-height: 1.6;
        font-size: 2rem;  
      }
      @include g.mq(sm) {
        line-height: 1.5;
        font-size: f.px2vw(18);
      }
    }

    .company {

      @include g.mq(md) {
        margin-top: 73px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(51);
      }

      .company-title {
        color: g.$col-green;
        font-weight: bold;

        @include g.mq(md) {
          font-size: 3rem;
          margin-bottom: 23px;  
        }
        @include g.mq(sm) {
          font-size: f.px2vw(24);
          margin-bottom: f.px2vw(22);
        }
      }
      .company-dl {

        @include g.mq(md) {
          margin-bottom: 117px;
        }
        @include g.mq(sm) {
          margin-bottom: f.px2vw(43);
        }

        dl {
          border-bottom: 1px solid g.$col-green;

          @include g.mq(md) {
            display: flex;
            align-items: baseline;  
            padding: 15px 0;
            font-size: 1.6rem;  
          }
          @include g.mq(sm) {
            padding: f.px2vw(15) 0;
            font-size: f.px2vw(15);  
          }

          dt {
            font-weight: bold;

            @include g.mq(md) {
              flex: 0 0 100px;
              margin-right: 90px;
              padding-left: 20px;  
            }
            @include g.mq(sm) {
              margin-bottom: f.px2vw(8);
            }
          }
          dd {

            @include g.mq(md) {
              flex: 0 1 100%;
              line-height: 1.625;
            }
            @include g.mq(sm) {
              line-height: 1.6;
              padding-left: f.px2vw(10);
            }

            strong {
              display: inline-block;
              font-weight: bold;

              @include g.mq(md) {
                margin-top: 12px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(12);
              }
            }
          }
        }
      }
      .company-box {

        @include g.mq(md) {
          margin-top: 50px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(50);
        }

        .box-title {
          font-weight: bold;
          color: g.$col-green;

          @include g.mq(md) {
            font-size: 2.4rem;
            padding-bottom: 5px;  
            border-bottom: 2px dotted g.$col-green;
          }
          @include g.mq(sm) {
            border-bottom: f.px2vw(2) dotted g.$col-green;
            line-height: 1.36;
            font-size: f.px2vw(22);
            padding-bottom: f.px2vw(5);
          }
        }
        .box-txt {
          line-height: 1.27;

          @include g.mq(md) {
            font-size: 1.8rem;
            padding-left: 20px;
            margin-top: 10px;  
          }
          @include g.mq(sm) {
            font-size: f.px2vw(18);
            margin-top: f.px2vw(9);  
          }
        }
        .box-list {

          @include g.mq(md) {
            margin: 14px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(20);
          }

          .item {
            .title {
              font-weight: bold;

              @include g.mq(md) {
                font-size: 1.8rem;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(18);
              }
            }
            .txt {
              position: relative;

              @include g.mq(md) {
                line-height: 1.6;
                font-size: 1.8rem;
                padding-left: 30px;  
                margin-top: 5px;
              }
              @include g.mq(sm) {
                line-height: 1.27;
                font-size: f.px2vw(18);
                padding-left: f.px2vw(28);
                margin-top: f.px2vw(5);  
              }

              &::before {
                content: "";
                position: absolute;
                left: 0;
                background-image: url("../img/about/heart-icon01.png");
                background-size: cover;
                background-repeat: no-repeat;

                @include g.mq(md) {
                  width: 20px;
                  height: 17px;  
                  top: 5px;
                }
                @include g.mq(sm) {
                  width: f.px2vw(20);
                  height: f.px2vw(17);  
                  top: f.px2vw(5);
                }
              }
            }

            &+.item {

              @include g.mq(md) {
                margin-top: 50px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(20);
              }
            }
          }
        }
      }
    }
  }

}
