@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
support
========================================================================== */
#support {

  .support-wrap {

    @include g.mq(md) {
      margin-top: 96px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(55);
    }

    .support-title {
      font-weight: bold;
      color: g.$col-green;
      letter-spacing: 0.1em;

      @include g.mq(md) {
        font-size: 3.2rem;
      }
      @include g.mq(sm) {
        font-size: f.px2vw(22);
        letter-spacing: -0.01em;
      }
    }
    .support-txt {

      @include g.mq(md) {
        line-height: 2;
        font-size: 1.6rem;
        margin-top: 15px;  
      }
      @include g.mq(sm) {
        line-height: 1.63;
        font-size: f.px2vw(16);
        margin-top: f.px2vw(16);
      }
    }
    .support-list {

      @include g.mq(md) {
        margin-top: 111px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(63);
      }

      .item {

        &+.item {

          @include g.mq(md) {
            margin-top: 66px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(60);
          }
        }

        .flex {

          @include g.mq(md) {
            display: flex;
          }

          .message {
            max-width: 700px;
            flex: 0 1 100%;  
          }
        }

        .message {

          .box {
            border-bottom: 1px solid g.$col-green;

            @include g.mq(md) {
              display: flex;
              align-items: center;  
              padding-bottom: 15px;
            }
            @include g.mq(sm) {
              padding-bottom: f.px2vw(8);
            }

            .step {
              align-items: center;
              background-color: g.$col-green;
              font-weight: bold;
              color: #fff;

              @include g.mq(md) {
                display: flex;
                border-radius: 6px;
                padding: 5px 8px;
                font-size: 2rem;
                margin-right: 14px;  
              }
              @include g.mq(sm) {
                display: inline-flex;
                border-radius: f.px2vw(6);
                padding: f.px2vw(5) f.px2vw(8);
                font-size: f.px2vw(16);
                margin-bottom: f.px2vw(9);
              }

              span {
                display: inline-block;
                background-color: #fff;
                color: g.$col-green;

                @include g.mq(md) {
                  border-radius: 9px;
                  padding: 3px 6px;
                  font-size: 1.3rem;
                  margin-right: 5px;  
                }
                @include g.mq(sm) {
                  border-radius: f.px2vw(9);
                  padding: f.px2vw(3) f.px2vw(6);
                  font-size: f.px2vw(12);
                  margin-right: f.px2vw(5);  
                }
              }
            }

            .title {
              font-weight: bold;

              @include g.mq(md) {
                font-size: 2.7rem;
              }
              @include g.mq(sm) {
                letter-spacing: -0.01em;
                line-height: 1.2;
                font-size: f.px2vw(24);
              }

              span {

                @include g.mq(md) {
                  font-size: 2.6rem;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(16);
                }
              }
            }
          }
          .txt {

            @include g.mq(md) {
              line-height: 1.5;
              font-size: 1.6rem;
              margin-top: 18px;  
            }
            @include g.mq(sm) {
              line-height: 1.63;
              font-size: f.px2vw(16);
              margin-top: f.px2vw(18);
            }

            span {  
              display: block;

              @include g.mq(md) {
                font-size: 1.4rem;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(16);
              }
            }
          }
        }
        .img {
          @include g.mq(md) {
            flex: 0 0 130px;
            margin-left: 83px;  
          }
          @include g.mq(sm) {
            margin: 0 auto;
            width: f.px2vw(175);
            margin-top: f.px2vw(20);
          }


          &.img02 {
            @include g.mq(md) {
              flex: 0 0 215px;
              margin-left: 43px;  
            }
            @include g.mq(sm) {
              width: f.px2vw(245);
            }
          }
        }
        .table {
          max-width: 737px;

          @include g.mq(md) {
            margin-top: -18px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(23);
          }

          .caption {
            @include g.mq(md) {
              font-size: 1.4rem;
              margin-bottom: 5px;  
            }
            @include g.mq(sm) {
              font-size: f.px2vw(14);
              margin-bottom: f.px2vw(5);
            }
          }
        }
      }
    }
  }
}
