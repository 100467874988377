@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
main
========================================================================== */
main {
  
  #top {
    overflow-x: clip;

    /*# 共通
    /*===========================================*/
    .top-title {
      font-weight: bold;
      letter-spacing: 0.1em;

      @include g.mq(md) {
        font-size: 5.6rem;
      }
      @include g.mq(sm) {
        font-size: f.px2vw(48);
      }

      span {
        font-weight: 400;
        letter-spacing: normal;

        @include g.mq(md) {
          font-size: 1.6rem;
          padding-left: 9px;  
        }
        @include g.mq(sm) {
          display: block;
          font-size: f.px2vw(16);
          margin-top: f.px2vw(10);
        }
      }
    }

    /*# MV
    /*===========================================*/
    .mv {
      position: relative;
      z-index: 0;

      .mv-swiper {
        overflow: visible !important;

        .item {
          position: relative;
        }

        .mv-swiper-pagination {
          width: auto;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;

          @include g.mq(md) {
            bottom: -30px;
          }
          @include g.mq(sm) {
            bottom: f.px2vw(12);
          }
  
          .swiper-pagination-bullet {
            background-color: #D9D9D9;
            border-radius: 50%;
            opacity: 1;
            @include g.mq(md) {
              width: 14px;
              height: 14px;
              margin: 0 7px;  
            }
            @include g.mq(sm) {
              width: f.px2vw(10);
              height: f.px2vw(10);
              margin: 0 f.px2vw(5);
            }
  
            &-active {
              background-color: #4CB57B;
            }
          }
        }
      }
    }

    .about {

      @include g.mq(md) {
        margin-top: 105px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(47);
      }

      .about-wrap {
        max-width: 870px;
        margin: 0 auto; 
        
        @include g.mq(md) {
          display: flex;
          justify-content: space-between;  
        }
        @include g.mq(sm) {
          position: relative;
          padding: 0 f.px2vw(20);
        }

        .message {

          @include g.mq(md) {
            flex: 0 1 100%;
          }
  
          .txt {

            @include g.mq(md) {
              line-height: 2;
              font-size: 1.6rem;  
            }
            @include g.mq(sm) {
              line-height: 1.86;
              font-size: f.px2vw(14);
            }
  
            &+.txt {

              @include g.mq(md) {
                margin-top: 16px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(20);
              }
            }
  
          }
          .button {

            @include g.mq(md) {
              margin-top: 32px;
            }
            @include g.mq(sm) {
              position: absolute;
              bottom: f.px2vw(-64);
            }
          }
        }
        .img {

          @include g.mq(md) {
            flex: 0 0 437px;
            margin: 16px 0 0 65px;  
          }
          @media screen and (min-width: 767px) and (max-width: 870px) {
            flex: 0 0 f.px2vw(437,870);
            margin: f.px2vw(16,870) 0 0 f.px2vw(65,870);
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(19);
          }
        }
      }
    }

    .event {
      position: relative;

      @include g.mq(md) {
        margin-top: 118px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(160);
      }

      .event-wrap {

        .event-list {

          @include g.mq(md) {
            display: flex;
            margin-top: 30px;
            column-gap: 27px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(30);
            padding: 0 f.px2vw(20);
          }

          .item {
            
            @include g.mq(md) {
              width: 31.5%;
            }

            &+.item {
              @include g.mq(sm) {
                margin-top: f.px2vw(30);
              }
            }

            &:hover {
              img {
                opacity: .7;
                transform: scale3d(1.1, 1.1, 1.1);              
              }
            }

            .img {
              overflow: hidden;

              @include g.mq(md) {
                border-radius: 10px;
              }
              @include g.mq(sm) {
                border-radius: f.px2vw(10);
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s ease;

                @include g.mq(md) {
                  border-radius: 10px;
                }
                @include g.mq(sm) {
                  border-radius: f.px2vw(10);
                }
              }
            }

            .message {

              @include g.mq(md) {
                margin-top: 10px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(10);
              }

              .title {
                font-weight: bold;
                line-height: 1.3;

                @include g.mq(md) {
                  font-size: 1.8rem;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(18);
                }
              }

              .tag-list {
                display: flex;  

                @include g.mq(md) {
                  margin-top: 8px;
                  gap: 5px;  
                }
                @include g.mq(sm) {
                  margin-top: f.px2vw(12);
                  gap: f.px2vw(5);
                }

                .tag {
                  border: 1px solid g.$font-col;

                  @include g.mq(md) {
                    border-radius: 4px;
                    font-size: 1.2rem;  
                    padding: 4px 10px;
                  }
                  @include g.mq(sm) {
                    font-size: f.px2vw(12);
                    border-radius: f.px2vw(2);
                    padding: f.px2vw(4) f.px2vw(10);
                  }
                }
              }
            }
          }
        }

        .more-button {
          text-align: right;

          @include g.mq(md) {
            margin-top: 10px;
          }
          @include g.mq(sm) {
            margin: f.px2vw(20) f.px2vw(20) 0 0;
          }
        }
      }

      .bg {
        position: absolute;
        z-index: -1;
        width: 637px;

        @include g.mq(md) {
          top: -87px;
          left: -355px;  
        }
        @include g.mq(sm) {
          width: f.px2vw(850);
          top: f.px2vw(-191);
          right: f.px2vw(-590);
        }
      }
    }

    .story {
      position: relative;

      @include g.mq(md) {
        margin-top: 92px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(129);
      }

      .story-wrap {
        max-width: 900px;

        .story-title {
          position: relative;
          color: g.$col-green;
          font-weight: bold;

          @include g.mq(md) {
            font-size: 3.6rem;
            padding-left: 30px;  
          }
          @include g.mq(sm) {
            font-size: f.px2vw(24);
          }

          .icon {
            position: absolute;
            left: 0;

            @include g.mq(md) {
              width: 115px;
              top: -50px;
            }
            @include g.mq(sm) {
              width: f.px2vw(102);
              top: f.px2vw(-47);
            }
          }
        }

        .story-box {

          @include g.mq(md) {
            display: flex;
            align-items: flex-end;  
            margin-top: 33px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(21);
          }

          .img {
            position: relative;
            z-index: 2;
            flex: 0 1 100%;
            margin-left: 30px;

            @include g.mq(sm) {
              margin-left: f.px2vw(16);
              border-radius: f.px2vw(10);
            }

            img {
              @include g.mq(md) {
                border-radius: 10px;
              }
              @include g.mq(sm) {
                border-radius: f.px2vw(10);
              }
            }

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background-image: url("../img/top/story-bg01.png");
              background-size: cover;
              background-repeat: no-repeat;
              z-index: -1;

              @include g.mq(md) {
                left: -30px;
                bottom: -30px;  
                border-radius: 10px;
              }
              @include g.mq(sm) {
                left: f.px2vw(-15);
                bottom: f.px2vw(-15);
                border-radius: f.px2vw(10);
              }
            }
          }
          .message {

            @include g.mq(md) {
              flex: 0 0 270px;
            }
            @include g.mq(sm) {
              margin-left: auto;
              width: 67%;
            }

            .txt {
              position: relative;
              z-index: 2;

              @include g.mq(md) {
                border-bottom: 1px solid g.$col-green;
                line-height: 1.3;
                padding: 0 0 17px 35px;
                font-size: 1.8rem;  
              }
              @include g.mq(sm) {
                line-height: 1.57;
                border-left: 2px solid g.$col-green;
                padding-left: f.px2vw(20);
                padding-top: f.px2vw(32);
                font-size: f.px2vw(14);
              }
            }
            .more-button {
              text-align: right;

              @include g.mq(md) {
                margin-top: 25px;
              }
              @include g.mq(sm) {
                margin: f.px2vw(25) f.px2vw(20) 0 0;
              }
            }
          }
        }
      }


      .bg {
        position: absolute;
        width: 637px;
        z-index: -1;

        @include g.mq(md) {
          top: -3px;
          right: -211px;  
        }
        @include g.mq(sm) {
          top: f.px2vw(-92);
          right: f.px2vw(-420);
        }
      }
    }

    .topics {

      @include g.mq(md) {
        overflow: hidden;
        margin-top: 202px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(63);
      }

      .topics-wrap {
        position: relative;

        .topics-swiper {

          @include g.mq(md) {
            margin-top: 33px;
            
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(30);
            padding: 0 f.px2vw(20);
          }

          .swiper-wrapper {
            @include g.mq(md) {
              max-height: 300px;
              height: 250px;
            }
            @include g.mq(sm) {
              display: flex;
              flex-wrap: wrap;
              gap: f.px2vw(25) f.px2vw(20);
            }
          }

          .topics-item {
            cursor: pointer;

            @include g.mq(md) {
              max-width: 250px;
            }

            @include g.mq(sm) {
              width: 46.5%;
              &:first-child {
                width: 100%;
              }
            }

            &:hover {
              img {
                opacity: .7;
                transform: scale3d(1.1, 1.1, 1.1);              
              }
            }

            .img {
              aspect-ratio: 234/146;
              overflow: hidden;

              @include g.mq(md) {
                border-radius: 10px 10px 0 0 ;
              }
              @include g.mq(sm) {
                border-radius: f.px2vw(10) f.px2vw(10) 0 0 ;                
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s ease;

                @include g.mq(md) {
                  border-radius: 10px 10px 0 0 ;
                }
                @include g.mq(sm) {
                  border-radius: f.px2vw(10) f.px2vw(10) 0 0 ;                
                }
              }
            }

            .message {

              @include g.mq(md) {
                margin-top: 8px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(10);
              }

              .date {
                font-weight: bold;
                color: g.$col-green;

                @include g.mq(md) {
                  font-size: 1.4rem;
                }
                @include g.mq(sm) {
                  font-weight: bold;
                  font-size: f.px2vw(12);
                }
              }
              .txt {

                @include g.mq(md) {
                  line-height: 1.5;
                  font-size: 1.6rem;
                  margin-top: 2px;  
                }
                @include g.mq(sm) {
                  line-height: 1.43;
                  font-size: f.px2vw(14);
                }
              }
            }
          }
          
          .cursor-box {
            position: absolute;
            max-width: 1000px;
            width: 100%;
            margin: 0 auto;
            top: -80px;

            @include g.mq(sm) {
              display: none;
            }

            .topics-button {
              cursor: pointer;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              top: 0;
              right: 0;
              width: 45px;  
              height: 45px;
              border: 1px solid g.$col-green;
              transition: all .3s;
              
              @include g.mq(sm) {
                display: none;
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-image: url("../img/cmn/arrow-icon02.png");
                background-size: cover;
                background-repeat: no-repeat;
                width: 17px;
                height: 8px;
                transition: all .3s;
              }

              &:hover {
                background-color: g.$col-green;
              }
            
              &-prev {
                right: 75px;

                &::before {
                  background-image: url("../img/cmn/arrow-icon03.png");
                  background-size: cover;
                  background-repeat: no-repeat;
                }

                &:hover {
                  &::before {
                    left: 40%;
                    background-image: url("../img/cmn/arrow-icon05.png");
                    background-size: cover;
                    background-repeat: no-repeat;    
                  }
                }
              }
              &-next {
                right: 0;

                &::before {
                  background-image: url("../img/cmn/arrow-icon02.png");
                  background-size: cover;
                  background-repeat: no-repeat;
                }

                &:hover {
                  &::before {
                    left: 60%;
                    background-image: url("../img/cmn/arrow-icon04.png");
                    background-size: cover;
                    background-repeat: no-repeat;    
                  }
                }
              }
              &.swiper-button-disabled {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    // お問い合わせ＆アクセス情報
    .contact {
      position: relative;

      @include g.mq(md) {
        margin-top: 68px;
        padding-top: 50px;
      }
      @include g.mq(sm) {
        padding-top: f.px2vw(75);
      }

      .contact-wrap {
        margin-top: 0;

        .contact-title {
          font-weight: bold;
          text-align: center;

          @include g.mq(md) {
            font-size: 3.2rem;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(24);
          }

          span {
            color: g.$col-green;
            font-weight: bold;
            display: block;

            @include g.mq(md) {
              font-size: 2.4rem;
              margin-bottom: 10px;  
            }
            @include g.mq(sm) {
              font-size: f.px2vw(16);
              margin-bottom: f.px2vw(10);
            }
          }
        }

        .contact-box {
          max-width: 760px;

          @include g.mq(md) {
            display: flex;
            justify-content: center;  
            margin: 50px auto 0;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(37);
          }

          .contact-txt {
            font-weight: bold;
            text-align: center;

            @include g.mq(md) {
              font-size: 1.8rem;
            }
            @include g.mq(sm) {
              font-size: f.px2vw(18);
            }
          }
            
          .tell {

            @include g.mq(md) {
              margin: 0;
              width: 50%;
              padding-right: 40px;  
            }
            @include g.mq(sm) {
              max-width: f.px2vw(300);
            }

            .box {

              @include g.mq(md) {
                margin-top: 14px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(9);
              }
            }
          }

          .maill {

            @include g.mq(md) {
              width: 50%;
              padding-left: 40px;  
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(20);
              padding: 0 f.px2vw(20); 
            }

            .contact-button01 {

              @include g.mq(md) {
                margin-top: 20px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(10);
              }

              a {
                width: 100%;
              }
            }
          }
        }

        .map-box {

          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;  
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(30);
          }

          .item {
            @include g.mq(md) {
              width: 49%;
            }

            &.google {
              aspect-ratio: 490/274;

              @include g.mq(sm) {
                margin-bottom: f.px2vw(17);
              }
              
              iframe {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        z-index: -1;
        width: 637px;

        &.bg01 {
          top: -87px;
          left: -438px; 

          @include g.mq(sm) {
            display: none;
          }
        }
        &.bg02 {
          top: 489px;
          right: -453px;
          transform: rotate(-31deg);
        }
      }
    }
  }
}