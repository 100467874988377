@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
works
========================================================================== */
#works {

  .works-wrap {
    @include g.mq(md) {
      margin-top: 86px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(40);
    }

    .head {

      .head-txt {
        @include g.mq(md) {
          line-height: 1.6;
          font-size: 2rem;  
        }
        @include g.mq(sm) {
          line-height: 1.5;
          font-size: f.px2vw(18);
        }
      }

      .head-list {
        display: flex;
        flex-wrap: wrap;

        @include g.mq(md) {
          margin-top: 30px;
          gap: 12px ;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(18);
          gap: f.px2vw(8);
        }

        .item {
          font-weight: 400;
          border: 1px solid g.$col-green;
          color: g.$col-green;

          @include g.mq(md) {
            border-radius: 4px;
            font-size: 1.6rem;  
          }
          @include g.mq(sm) {
            border-radius: f.px2vw(4);
            font-size: f.px2vw(14);
          }

          &.active {
            font-weight: bold;
            background-color: g.$col-green;
            color: #fff;
          }
          
          a {
            display: inline-block;
            cursor: pointer;
            width: 100%;
            height: 100%;

            @include g.mq(md) {
              padding: 5px 25px;
            }
            @include g.mq(sm) {
              padding: f.px2vw(5) f.px2vw(15);
            }
          }
        }
      }
    }

    .body {

      @include g.mq(md) {
        margin-top: 86px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(57);
      }

      .body-list {

        @include g.mq(md) {
          display: flex;
          flex-wrap: wrap;
          gap: 50px 35px;  
        }
        @media screen and (min-width:767px) and (max-width: 1040px) {
          gap: 50px f.px2vw(34,1040);
        }
        @include g.mq(sm) {
          padding: 0 f.px2vw(20);
        }

        .item {
          
          @include g.mq(md) {
            width: 31%;
          }
          @include g.mq(sm) {
            &+.item {
              margin-top: f.px2vw(40);
            }
          }
          
          &:nth-child(1) {
            transition-delay: 80ms;
          }
          &:nth-child(2) {
            transition-delay: 160ms;
          }
          &:nth-child(3) {
            transition-delay: 240ms;
          }
          &:nth-child(4) {
            transition-delay: 320ms;
          }
          &:nth-child(5) {
            transition-delay: 400ms;
          }
          &:nth-child(6) {
            transition-delay: 480ms;
          }
          &:nth-child(7) {
            transition-delay: 560ms;
          }
          &:nth-child(8) {
            transition-delay: 640ms;
          }
          &:nth-child(9) {
            transition-delay: 720ms;
          }
          &:nth-child(10) {
            transition-delay: 800ms;
          }
          &:nth-child(11) {
            transition-delay: 880ms;
          }
          &:nth-child(12) {
            transition-delay: 960ms;
          }
          &:nth-child(13) {
            transition-delay: 1040ms;
          }
          &:nth-child(14) {
            transition-delay: 1120ms;
          }
          &:nth-child(15) {
            transition-delay: 1200ms;
          }
          &:nth-child(16) {
            transition-delay: 1280ms;
          }

          &:hover {
            img {
              opacity: .7;
              transform: scale3d(1.1, 1.1, 1.1);              
            }
          }

          .img {
            overflow: hidden;

            @include g.mq(md) {
              aspect-ratio: 310/187;
              border-radius: 10px 10px 0 0 ;
            }
            @include g.mq(sm) {
              border-radius: f.px2vw(10) f.px2vw(10) 0 0 ;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-fit: cover;
              transition: all 0.5s ease;

              @include g.mq(md) {
                border-radius: 10px 10px 0 0 ;
              }
              @include g.mq(sm) {
                border-radius: f.px2vw(10) f.px2vw(10) 0 0 ;
              }
            }
          }
          .message {

            @include g.mq(md) {
              margin-top: 10px;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(10);
            }

            .title {
              font-weight: bold;
              line-height: 1.3;
              
              @include g.mq(md) {
                font-size: 1.8rem;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(18);
              }
            }
            .tag-list {
          
              @include g.mq(md) {
                margin-top: 6px;  
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(14);
              }
            }
          }
        }
      }
    }
  }
}

#works-single {

  .single-wrap {
    max-width: 880px;
    margin: 0 auto;

    @include g.mq(md) {
      margin-top: 92px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(60);
    }

    .single-title {
      font-weight: bold;
      border-bottom: 1px solid g.$col-green;

      @include g.mq(md) {
        line-height: 1.3;
        padding: 10px 0 13px;
        font-size: 3rem;
      }
      @include g.mq(sm) {
        line-height: 1.36;
        font-size: f.px2vw(22);
        padding: f.px2vw(15) 0 f.px2vw(10);
      }
    }

    .head {

      @include g.mq(md) {
        display: flex;
        margin-top: 30px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(20);
      }

      .message {

        @include g.mq(md) {
          flex: 0 0 265px;
          margin-right: 35px;  
        }

        .title {
          position: relative;
          color: g.$col-green;
          font-weight: bold;

          @include g.mq(md) {
            font-size: 2rem;
            padding-left: 25px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(20);
            padding-left: f.px2vw(25);  
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
        dl {
          display: flex;

          @include g.mq(md) {
            font-size: 1.6rem;
            margin-top: 10px;  
          }
          @include g.mq(sm) {
            font-size: f.px2vw(16);
            margin-top: f.px2vw(14);  
          }

          dt {
            font-weight: 400;
          }
        }

        .txt {
          line-height: 1.75;

          @include g.mq(md) {
            font-size: 1.6rem;
            margin-top: 8px;  
          }
          @include g.mq(sm) {
            font-size: f.px2vw(16);
            margin-top: f.px2vw(8);  
          }
        }

        .date {
          .title {
            &::before {
              background-image: url("../img/works/home-icon01.png");
              background-size: cover;
              background-repeat: no-repeat;

              @include g.mq(md) {
                width: 22px;
                height: 19px;  
              }
              @include g.mq(sm) {
                width: f.px2vw(22);
                height: f.px2vw(19);  
              }
            }
          }
        }
        .tool {
          margin-top: 24px;
          .title {
            &::before {
              background-image: url("../img/works/tool-icon01.png");
              background-size: cover;
              background-repeat: no-repeat;

              @include g.mq(md) {
                width: 20px;
                height: 19px;  
              }
              @include g.mq(sm) {
                width: f.px2vw(20);
                height: f.px2vw(19);  
              }
            }
          }
        }
      }
      .img {

        @include g.mq(md) {
          flex: 0 1 100%;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(20);
        }

        img {
          @include g.mq(md) {
            border-radius: 10px;
          }
          @include g.mq(sm) {
            border-radius: f.px2vw(10);
          }
        }

        .caption {

          @include g.mq(md) {
            margin-top: 5px;
            font-size: 1.4rem;  
          }
          @include g.mq(sm) {
            line-height: 1.57;
            font-size: f.px2vw(14);
            margin-top: f.px2vw(5);
          }
        }
      }
    }

    .body {

      @include g.mq(md) {
        margin-top: 30px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(20);
      }
    }

    .back-button {

      @include g.mq(md) {
        margin-top: 110px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(40);
      }
    }
  }
}