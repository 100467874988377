@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
staff
========================================================================== */
#staff {

  .staff-wrap {

    @include g.mq(md) {
      margin-top: 76px;
    }
    @include g.mq(sm) {
      margin-top: 40px;
    }

    .staff-txt {

      @include g.mq(md) {
        line-height: 1.6;
        font-size: 2rem;  
      }
      @include g.mq(sm) {
        line-height: 1.625;
        font-size: 16px;
      }
    }

    .staff-list {

      @include g.mq(md) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 76px;
        gap: 30px;  
      }
      @include g.mq(sm) {
        margin-top: 54px;
      }

      .item {
        border: 1px solid g.$col-green;

        @include g.mq(md) {
          cursor: pointer;
          display: flex;
          flex-direction: column;  
          width: 31.3%;
          border-radius: 10px;
          padding: 20px 20px 15px ;
        }
        @include g.mq(sm) {
          padding: 20px;
          border-radius: 10px;

          &+.item {
            margin-top: 30px;
          }
        }

        &:hover {
          .more {
            transform: translateX(10px);
          }
        }

        .box {
          display: flex;

          .img {

            @include g.mq(md) {
              flex: 0 0 86px;
              margin-right: 10px;  
            }
            @include g.mq(sm) {
              flex: 0 0 80px;
              margin-right: 10px;
            }
          }
          .message {
            flex: 0 1 100%;

            .name {
              font-weight: bold;

              @include g.mq(md) {
                line-height: 1.3;
                font-size: 2.6rem;  
              }
              @include g.mq(sm) {
                line-height: 1.4;
                font-size: 24px;
              }

              span {
                display: block;
                font-weight: 400;

                @include g.mq(md) {
                  font-size: 1.4rem;
                }
                @include g.mq(sm) {
                  font-size: 13px;
                }

                &.en {
                  letter-spacing: 0.005em;
                }
              }
            }
          }
        }
        .txt {

          @include g.mq(md) {
            line-height: 1.5;
            font-size: 1.6rem;
            margin: 10px 0;  
          }
          @include g.mq(sm) {
            line-height: 1.57;
            font-size: 14px;
            margin-top: 10px;
          }
        } 
        .more {
          cursor: pointer;
          position: relative;
          color: g.$col-green;
          text-align: right;
          margin-top: auto;
          transition: all .3s;

          @include g.mq(md) {
            font-size: 1.4rem;
            padding-right: 15px;  
          }
          @include g.mq(sm) {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: g.$col-green;
            color: #fff;
            font-weight: bold;
            width: 187px;
            height: 35px;
            border-radius: 19px;
            margin: 0 auto;
            margin-top: 15px;
            font-size: 15px;
          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            background-size: cover;
            background-repeat: no-repeat;

            @include g.mq(md) {
              background-image: url("../img/cmn/arrow-icon02.png");
              width: 12px;
              height: 6px;  
            }
            @include g.mq(sm) {
              background-image: url("../img/cmn/tab-icon03.png");
              width: 12px;
              height: 12px;
              right: 15px;
            }
          }
        }
      }
    }
  }

}
