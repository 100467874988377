@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
  header
========================================================================== */
header {
  position: fixed;
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  
  .header-wrap {
    position: relative;
    z-index: 10;

    @include g.mq(md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;  
    }

    .logo {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;

      @include g.mq(md) {
        border-radius: 0 0 30px 0;  
      }
      @include g.mq(sm) {
        border-radius: 0 0 f.px2vw(20) 0;
      }

      a {
        display: inline-block;

        @include g.mq(md) {
          padding: 16px 42px 19px 35px;
          border-radius: 0 0 30px 0;  
        }
        @include g.mq(sm) {
          padding: f.px2vw(16) f.px2vw(27) f.px2vw(18) f.px2vw(21);
          border-radius: 0 0 f.px2vw(20) 0;
        }
      }

      .img {

        @include g.mq(md) {
          width: 208px;
        }
        @include g.mq(sm) {
          width: f.px2vw(130);
        }
      }

      &.under {

        a {

          @include g.mq(md) {
            padding: 10px 26px 12px 23px;
          }
        }

        .img {

          @include g.mq(md) {
            width: 132px;
          }
          @include g.mq(sm) {
            width: f.px2vw(130);
          }
        }
      }
    }
    .box {
      position: fixed;
      top: 0;
      right: 0;

      @include g.mq(md) {
        display: flex;
        align-items: center;
        padding: 18px 18px 0 0 ;
      }
      @include g.mq(sm) {
        background-color: g.$col-green;
        width: f.px2vw(60);
        height: f.px2vw(205);
        border-radius: 0 0 0 f.px2vw(30);
      }

      .menu-icon {
        position: relative;
        cursor: pointer;
        transition: all .3s;

        @include g.mq(md) {
          background-color: g.$col-green;
          border-radius: 50%;
          width: 66px;
          height: 66px;  
        }
        @include g.mq(sm) {
          border-bottom: 1px solid rgba($color: #fff, $alpha: .5);
          width: 100%;
          height: f.px2vw(18);
          padding: f.px2vw(40) f.px2vw(30);  
        }
        &:hover {
          opacity: .8;
        }

        span {
          position: absolute;
          background-color: #fff;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 5px;
          transition: all .3s;

          @include g.mq(md) {
            width: 24px;
            height: 2px;  
          }
          @include g.mq(sm) {
            width: f.px2vw(24);
            height: f.px2vw(2);  
          }

          &:first-child {
            top: 38%;
          }
          &:nth-of-type(2) {
            top: 50%;
            transform: translate(-50%,-50%);
          }
          &:last-child {
            bottom: 38%;
          }
        }
        
        &.open {

          span {
            background-color: #fff;

            &:first-child {
              top: 50%;
              transform:translateX(-50%) rotate(45deg);
            }
            &:nth-child(2n) {
              display: none;
            }
            &:last-child {
              top: 50%;
              transform:translateX(-50%) rotate(-45deg);
            }
          }
        }
      }

      // PC
      .contact {
        margin-right: 21px;

        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid g.$col-green;
          font-weight: bold;
          background-color: #fff;
          color: g.$col-green;
          width: 151px;
          height: 37px;
          border-radius: 37px;  
          font-size: 1.4rem;
          padding-left: 20px;
          transition: all .3s;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            background-image: url("../img/cmn/contact-icon01.png");
            background-size: cover;
            background-repeat: no-repeat;
            width: 17px;
            height: 12px;
            transition: all .3s;
          }

          &:hover {
            background-color: g.$col-green;
            color: #fff;

            &::before {
              background-image: url("../img/cmn/contact-icon02.png");
            }
          }

        }
      }

      // SP
      .sp-contact {
        padding-top: f.px2vw(30);
        width: f.px2vw(26);
        margin: 0 auto;
      }
      .sp-tell {
        width: f.px2vw(21);
        padding-top: f.px2vw(20);
        margin: 0 auto;
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    background-image: url("../img/cmn/menu-bg01.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;

    @include g.mq(md) {
      padding: 124px 0;
    }
    @include g.mq(sm) {
      overflow-y: auto;
      padding: f.px2vw(116) 0 f.px2vw(60);
    }

    .content {
      position: relative;
      max-width: 1200px;
    }

    .menu-wrap {
      
      @include g.mq(md) {
        display: flex;
        justify-content: space-between;  
        max-width: 1000px;
        margin: 0 auto;
      }
      @include g.mq(sm) {
        padding: 0 f.px2vw(20);
      }

      .img {

        @include g.mq(md) {
          position: absolute;
          top: 12px;
          right: 0;
          width: 536px;
          z-index: -1;
        }
        @media screen and (min-width:767px) and (max-width: 1200px) {
          right: 0;
          width: f.px2vw(536,1200);
        }
      }

      .list-content {

        @include g.mq(md) {
          margin-top: 63px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(45);
        }
  
        .box {

          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
          }
          
          .list {

            &:first-child {
              @include g.mq(sm) {
                margin-top: 0;
              }
            }
  
            .item {
              position: relative;

              transition: all .3s;

              &:hover {
                color: g.$col-green;
              }

              &.comming {
                a {
                  color: #acacac;
                  pointer-events: none;
                }
              }

              a {
                position: relative;

                @include g.mq(md) {
                  display: inline-block;
                  line-height: 1.5;
                  font-size: 1.6rem;
                  padding: 8px 0 8px 20px;  

                  &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: g.$col-green;
                    width: 12px;
                    height: 2px;
                  } 
                }
                @include g.mq(sm) {
                  display: block;
                  font-weight: bold;
                  letter-spacing: 0.05em;
                  border-bottom: 2px dotted g.$col-green;
                  font-size: f.px2vw(16);
                  padding: f.px2vw(16) 0;

                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: f.px2vw(7) 0 f.px2vw(7) f.px2vw(8);
                    border-color: transparent transparent transparent g.$col-green;
                    border-radius: f.px2vw(10);
                  }
                }

                
              }

              &.tab {
                .link {
                  position: relative;

                  @include g.mq(md) {
                    display: inline-block;
                  }
                  @include g.mq(sm) {
                    display: block;
                  }
                  
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url("../img/cmn/tab-icon01.png");
                    background-size: cover;
                    background-repeat: no-repeat;

                    @include g.mq(md) {
                      width: 13px;
                      height: 10px;
                      right: -20px;  
                    }
                    @include g.mq(sm) {
                      border-width: 0;
                      border-color: transparent;
                      border-radius: 0;  
                      width: f.px2vw(20);
                      height: f.px2vw(15);
                      right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .discussion-box {

          @include g.mq(md) {
            margin-top: 36px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(34);
          }

          a {
            display: flex;

            .icon {
              position: relative;
              background-color: g.$col-green;
              border-radius: 50%;
              border: 2px solid g.$col-green;
              transition: all .3s;

              @include g.mq(md) {
                flex: 0 0 45px;
                width: 45px;
                height: 45px;
                margin-right: 12px;  
              }
              @include g.mq(sm) {
                flex: 0 0 f.px2vw(45);
                width: f.px2vw(45);
                height: f.px2vw(45);
                margin-right: f.px2vw(12);  
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-image: url("../img/cmn/arrow-icon01.png");
                background-size: cover;
                background-repeat: no-repeat;
                transition: all .3s;

                @include g.mq(md) {
                  width: 17px;
                  height: 8px;  
                }
                @include g.mq(sm) {
                  width: f.px2vw(17);
                  height: f.px2vw(8);  
                }
              }
            }
            &:hover {
              .icon {
                background-color: #fff;
                &::before {
                  left: 60%;
                  background-image: url("../img/cmn/arrow-icon02.png");
                  background-size: cover;
                  background-repeat: no-repeat;  
                }
              }
            }
            .message {

              .title {
                color: g.$col-green;
                font-weight: bold;
                line-height: 1.2;

                @include g.mq(md) {
                  font-size: 2rem;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(20);
                }

                span {
                  font-weight: 400;
                  display: block;

                  @include g.mq(md) {
                    font-size: 1.3rem;
                  }
                  @include g.mq(sm) {
                    font-size: f.px2vw(12);
                  }
                }
              }
              .txt {
                line-height: 1.5;
                @include g.mq(md) {
                  font-size: 1.3rem;
                  margin-top: 5px;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  margin-top: f.px2vw(5);
                }
              }
            }
          }
        }
      }
    }

    .bottom-wrap {

      @include g.mq(md) {
        display: flex;
        align-items: flex-end;  
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 58px;
      }
      @media screen and (min-width:767px) and (max-width: 1040px) {
        margin-top: 0;
      }
      @include g.mq(sm) {
        padding: 0 f.px2vw(20);
      }

      .contact {
        .tell {

          @include g.mq(sm) {
            margin-top: f.px2vw(50);
          }
          
          .date {
            background-color: #fff;
          }
        } 

        .contact-button01 {
          
          @include g.mq(md) {
            margin-top: 27px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(15);
          }
        }
      }

      .company {
        
        @include g.mq(md) {
          flex: 0 0 351px;
          margin-left: 30px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(30);
        }
        
        dl {
          dt {
            font-weight: bold;
            color: g.$col-green;
            
            @include g.mq(md) {
              font-size: 1.6rem;
            }
            @include g.mq(sm) {
              line-height: 1.375;
              font-size: f.px2vw(16);
            }
          }
          dd {

            @include g.mq(md) {
              font-size: 1.3rem;
              line-height: 2.3;
            }
            @include g.mq(sm) {
              line-height: 1.38;
              font-size: f.px2vw(13);
            }
  
            .map {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              letter-spacing: 0.05em;
              color: #fff;
              background-color: g.$col-green;

              @include g.mq(md) {
                margin-left: 9px;
                font-size: 1.4rem;
                width: 47px;
                height: 23px;
                border-radius: 3px;  
              }
              @include g.mq(sm) {
                width: f.px2vw(43);
                height: f.px2vw(21);
                border-radius: f.px2vw(3);
                margin-left: f.px2vw(10);
                font-size: f.px2vw(12);
              }
            }
          }
          &+dl {

            @include g.mq(md) {
              margin-top: 21px;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(21);
            }
          }
        }
      }      
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}
