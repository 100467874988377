@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
project
========================================================================== */
#project {

  .project-mv {
    background-color: #E1F8CA;

    @include g.mq(md) {
      padding: 113px 0 35px;
    }
    @include g.mq(sm) {
      padding: f.px2vw(127) 0 f.px2vw(70);
    }
  }
  .project-wrap {
    position: relative;

    @include g.mq(md) {
      margin-top: 90px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(61);
    }

    .head {

      .head-title {
        text-align: center;
        font-weight: 500;
        letter-spacing: 0.05em;

        @include g.mq(md) {
          font-size: 4.4rem;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(30);
        }
      }
      .head-box {
        background-color: #E1F8CA;

        @include g.mq(md) {
          margin-top: 27px;
          border-radius: 10px;
          padding: 54px 60px;
        }
        @include g.mq(sm) {
          padding: f.px2vw(30) f.px2vw(20);
          border-radius: f.px2vw(10);
          margin-top: f.px2vw(19);
        }

        .box-title {
          position: relative;
          font-weight: bold;
          
          @include g.mq(md) {
            font-size: 2.2rem;
            padding-left: 36px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(18);
            padding-left: f.px2vw(31);
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background-image: url("../img/project/home-icon01.png");
            background-size: cover;
            background-repeat: no-repeat;

            @include g.mq(md) {
              width: 30px;
              height: 22px;  
            }
            @include g.mq(sm) {
              width: f.px2vw(30);
              height: f.px2vw(22);  
            }
          }
        }
        .container {

          @include g.mq(md) {
            display: flex;
            justify-content: space-between;  
            margin-top: 12px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(12);
          }

          .img {
            @include g.mq(md) {
              width: 30.7%;
            }
          }

          dl {
            @include g.mq(md) {
              width: 30.7%;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(31);
            }
            dt {
              font-weight: bold;
              color: #43BA6F;
              border-bottom: 1px solid #43BA6F;

              @include g.mq(md) {
                font-size: 1.8rem;
                padding-bottom: 5px;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(18);
                padding-bottom: f.px2vw(11);
              }
            }
            dd {

              @include g.mq(md) {
                line-height: 2;
                margin-top: 20px;
                font-size: 1.6rem;
              }
              @include g.mq(sm) {
                line-height: 1.625;
                margin-top: f.px2vw(17);
                font-size: f.px2vw(16);
              }

              .img {
                @include g.mq(md) {
                  width: 80%;
                }
                @include g.mq(sm) {
                  max-width: f.px2vw(242);
                }
              }
            }
          }
        }
        .box-txt {

          @include g.mq(md) {
            line-height: 2;
            margin-top: 24px;
            font-size: 1.6rem;
          }
          @include g.mq(sm) {
            line-height: 1.625;
            margin-top: f.px2vw(28);
            font-size: f.px2vw(16);
          }
        }
      }
    }
    .body {
      max-width: 800px;
      margin: 0 auto;

      @include g.mq(md) {
        margin-top: 80px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(60);
      }

      .box {
        .title {
          color: #4CB57B;
          font-weight: bold;
          border-bottom: 1px solid #4CB57B;

          @include g.mq(md) {
            display: flex;
            align-items: center;  
            font-size: 2.2rem;
            padding-bottom: 12px;
            margin-bottom: 15px;
          }
          @include g.mq(sm) {
            line-height: 1.4;
            font-size: f.px2vw(20);
            padding-bottom: f.px2vw(13);
            margin-bottom: f.px2vw(12);
          }

          span {
            position: relative;
            background-color: #4CB57B;
            color: #fff;

            @include g.mq(md) {
              display: block;
              font-size: 1.8rem;
              padding: 7px 28px 7px 11px;
              margin-right: 8px;
            }
            @include g.mq(sm) {
              display: inline-block;
              font-size: f.px2vw(18);
              padding: f.px2vw(3) f.px2vw(28) f.px2vw(3) f.px2vw(11);
              margin-bottom: f.px2vw(10);
            }

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              width:0;
              height:0;
              border-style:solid;
              border-color: transparent #FFFFFF transparent transparent;

              @include g.mq(md) {
                border-width: 16px 16px 16px 0;
              }
              @include g.mq(sm) {
                border-width: f.px2vw(16) f.px2vw(16) f.px2vw(16) 0;
              }
            }
          }
        }
        strong {
          display: block;
          font-weight: bold;

          @include g.mq(md) {
            line-height: 1.7;
            font-size: 1.8rem;
          }
          @include g.mq(sm) {
            line-height: 1.3;
            font-size: f.px2vw(18);
          }
        }
        .txt {

          @include g.mq(md) {
            line-height: 2;
            font-size: 1.6rem;
          }
          @include g.mq(sm) {
            line-height: 1.625;
            margin-top: f.px2vw(10);
            font-size: f.px2vw(16);
          }
        }
        .images {
          margin: 0 auto;
          
          @include g.mq(md) {
            margin-top:40px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(30);
          }

          .img {
            margin: 0 auto;
            
            .caption {
              @include g.mq(md) {
                line-height: 1.57;
                margin-top: 5px;
                font-size: 1.4rem;
              }
              @include g.mq(sm) {
                line-height: 1.5;
                margin-top: f.px2vw(5);
                font-size: f.px2vw(12);
              }
            }
          }
        }
        .flex {
          display: flex;
          justify-content: space-between;
        }

        &+.box {

          @include g.mq(md) {
            margin-top: 52px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(50);
          }
        }
      }
    }
  }
}

