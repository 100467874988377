@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
reform
========================================================================== */
#reform {

  .reform-wrap {

    @include g.mq(md) {
      margin-top: 90px;
    }
    @include g.mq(sm) {
      margin-top: 40px;
    }

    .head {

      .head-title {
        font-weight: bold;
        color: g.$col-green;

        @include g.mq(md) {
          text-align: center;
          letter-spacing: 0.1em;
          line-height: 1.44;
          font-size: 3.2rem;
        }
        @include g.mq(sm) {
          line-height: 1.36;
          font-size: 22px;
        }
      }
      .head-txt {

        @include g.mq(md) {
          text-align: center;
          line-height: 2;
          font-size: 1.6rem;
          margin-top: 15px;  
        }
        @include g.mq(sm) {
          line-height: 1.63;
          margin-top: 15px;
          font-size: 16px;
        }
      }
      .head-img {
        margin: 0 auto;

        @include g.mq(md) {
          width: 85px;
          margin-top: 50px;
        }
        @include g.mq(sm) {
          width: 88px;
          margin-top: 40px;
        }
      }
      .head-contact {
        position: relative;
        border: 1px solid #BFBFBF;

        @include g.mq(md) {
          border-radius: 20px;
          margin-top: 70px;
          padding: 35px 20px 50px;  
        }
        @include g.mq(sm) {
          margin-top: 58px;
          border-radius: 20px;
          padding: 40px 20px 30px;
        }

        .contact-title {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          font-weight: bold;

          @include g.mq(md) {
            top: -12px;
            font-size: 2.4rem;
            padding: 0 45px;  
          }
          @include g.mq(sm) {
            width: 90%;
            top: -12px;
            text-align: center;
            font-size: 20px;
          }
        }
        .buttons-box {
          max-width: 868px;
          margin: 0 auto;

          @include g.mq(md) {
            display: flex;
            margin-top: 23px;
            column-gap: 15px;  
          }
          @include g.mq(sm) {
            margin-top: 30px;
          }

          .cmn-button02 {
            width: 100%;

            @include g.mq(sm) {
              &+.cmn-button02 {
                margin-top: 15px;
              }
            }

            p {
              position: relative;
              padding-left: 30px;

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background-size: contain;
                background-repeat: no-repeat;
                transition: all .3s;
              }
            }
          }

          .room {

            p {
              &::before {
                background-image: url("../img/cmn/room-icon01.png");

                @include g.mq(md) {
                  width: 21px;
                  height: 21px;  
                }
                @include g.mq(sm) {
                  width: 21px;
                  height: 21px;  
                }
              }
              
            }
            &:hover {
              p {
                &::before {
                  background-image: url("../img/cmn/room-icon02.png");
                }
              }
            }
          }
          .online {
            p {

              &::before {
                background-image: url("../img/cmn/online-icon01.png");

                @include g.mq(md) {
                  width: 25px;
                  height: 19px;  
                }
                @include g.mq(sm) {
                  width: 25px;
                  height: 19px;  
                }
              }
            }
            &:hover {
              p {
                &::before {
                  background-image: url("../img/cmn/online-icon02.png");
                }
              }
            }            
          }
          .contact {
            p {

              &::before {
                background-image: url("../img/cmn/contact-icon02.png");

                @include g.mq(md) {
                  width: 25px;
                  height: 17px;  
                }
                @include g.mq(sm) {
                  width: 25px;
                  height: 17px;  
                }
              }
            }
            &:hover {
              p {
                &::before {
                  background-image: url("../img/cmn/contact-icon01.png");
                }
              }
            }            
          }
        }
      }
    }

    .body {

      @include g.mq(md) {
        margin-top: 146px;
      }
      @include g.mq(sm) {
        margin-top: 75px;
      }

      .flex {

        @include g.mq(md) {
          display: flex;
          flex-wrap: wrap;
          gap: 64px 50px;  
        }

        &+.flex {

          @include g.mq(md) {
            margin-top: 30px;
          }
          @include g.mq(sm) {
            margin-top: 50px;
          }
        }

        .flex-item {

          @include g.mq(md) {
            width: 46%;
          }
          @include g.mq(sm) {
            &+.flex-item {
              margin-top: 40px;
            }
          }
        }
      }

      .body-box {

        @include g.mq(md) {
          margin-bottom: 100px;
        }
        @include g.mq(sm) {
          margin-bottom: 80px;
        }

        .box-title {
          position: relative;
          font-weight: bold;
          border-bottom: 1px solid g.$col-green;

          @include g.mq(md) {
            font-size: 3rem;
            padding: 0 0 14px 58px;
            margin-bottom: 43px;  
          }
          @include g.mq(sm) {
            font-size: 24px;
            padding: 0 0 13px 45px;
            margin-bottom: 29px;          
          }

          span {

            @include g.mq(md) {
              font-size: 2.6rem;
            }
            @include g.mq(sm) {
              font-size: 16px;
            }
          }

          &::before {
            content: "";
            position: absolute;
            background-image: url("../img/reform/heart-icon01.png");
            background-size: contain;
            background-repeat: no-repeat;

            @include g.mq(md) {
              top: -6px;
              left: 10px;  
              width: 38px;
              height: 48px;  
            }
            @include g.mq(sm) {
              top: 30%;
              transform: translateY(-50%);
              left: 0;  
              width: 38px;
              height: 48px;
            }
          }
        }
        .title {
          font-weight: bold;
          color: g.$col-green;

          @include g.mq(md) {
            font-size: 2.4rem;
            margin-bottom: 10px;  
          }
          @include g.mq(sm) {
            font-size: 20px;
            margin-bottom: 7px;
          }
        }
        strong {
          font-weight: bold;
          line-height: 1.3;

          @include g.mq(md) {
            font-size: 1.8rem;
          }
          @include g.mq(sm) {
            font-size: 18px;
          }
        }
        .img {
          position: relative;

          @include g.mq(md) {
            margin-top: 20px;
          }
          @include g.mq(sm) {
            margin-top: 10px;
          }

          p {
            text-align: right;

            @include g.mq(md) {
              font-size: 1.4rem;
              margin-top: 5px;
            }
            @include g.mq(sm) {
              font-size: 12px;
              margin-top: 10px;
            }
          }

          &.clover {
            @include g.mq(sm) {
              padding: 0 20px;
            }
          }

          .button {
            position: absolute;
            right: 0;
            bottom: 0;
            border: 1px solid g.$col-green;
            font-weight: bold;
            color: g.$col-green;
            transition: all .3s;

            @include g.mq(md) {
              max-width: 283px;
              width: 100%;
              height: 43px;
              border-radius: 37px;
            }
            @include g.mq(sm) {
              right: 20px;
              width: 197px;
              height: 30px;
              border-radius: 37px;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
  
              @include g.mq(md) {
                font-weight: bold;
                font-size: 1.6rem;  
              }
              @include g.mq(sm) {
                font-size: 12px;  
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent transparent transparent g.$col-green;   
                transition: all .3s; 
                
                @include g.mq(md) {
                  right: 8px;
                  border-width: 5.5px 0 5.5px 6px;
                }
                @include g.mq(sm) {
                  right: 3px;
                  border-width: 5.5px 0 5.5px 6px;
                }
              }
            }

            &:hover {
              background-color: g.$col-green;
              color: #fff;

              a {
                &::after {
                  border-color: transparent transparent transparent #fff;    
                }
              }
            }
          }
        }

        .txt {

          @include g.mq(md) {
            line-height: 1.5;
            font-size: 1.6rem;
            margin-top: 20px;  
          }
          @include g.mq(sm) {
            line-height: 1.63;
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      .banner {
        background-color: #FBF9F7;

        @include g.mq(md) {
          display: flex;
          padding: 25px 30px;
          border-radius: 10px;
          margin: -12px 0 107px;  
        }
        @include g.mq(sm) {
          padding: 30px 20px;
          border-radius: 10px;
          margin-bottom: 90px;
        }

        .img {

          @include g.mq(md) {
            flex: 0 0 260px;
            margin-right: 25px;  
          }
          @include g.mq(sm) {
            padding: 0 20px;
          }
        }
        .message {

          @include g.mq(md) {
            flex: 0 1 100%;
          }

          strong {
            position: relative;
            display: flex;
            align-items: center;
            background-color: g.$col-green;
            color: #fff;
            font-weight: bold;

            @include g.mq(md) {
              width: 240px;
              height: 42px;
              font-size: 1.8rem;
              padding: 10px 20px;  
            }
            @include g.mq(sm) {
              width: 240px;
              height: 42px;
              font-size: 1.8rem;
              padding: 10px 20px;  
              margin-top: 17px;
            }

            &::after {
              content: "";
              position: absolute;
              right: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-color: transparent #FBF9F7 transparent transparent;

              @include g.mq(md) {
                border-width: 21px 24px 21px 0;
              }
              @include g.mq(sm) {
                border-width: 21px 24px 21px 0;
              }
            }
          }
          .title {
            font-weight: bold;
            line-height: 1.3;

            @include g.mq(md) {
              font-size: 1.8rem;
              margin-top: 12px;  
            }
            @include g.mq(sm) {
              font-size: 1.8rem;
              margin-top: 12px;  
            }
          }
          .txt {

            @include g.mq(md) {
              line-height: 1.71;
              margin-top: 11px;  
              font-size: 1.4rem;
            }
            @include g.mq(sm) {
              line-height: 1.5;
              margin-top: 11px;  
              font-size: 12px;
            }
          }
        }
      }

      .maker {

        .title {
          font-weight: bold;
          text-align: center;

          @include g.mq(md) {
            font-size: 2.8rem;
          }
          @include g.mq(sm) {
            font-size: 22px;
          }
        }
        .img {

          @include g.mq(md) {
            margin-top: 48px;
            padding: 0 40px;  
          }
          @include g.mq(sm) {
            margin-top: 20px;
            padding: 0 20px;
          }
        }
      }
    }
  }

}

