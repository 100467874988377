@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# 共通
==============================================================*/
.content {
  max-width: 1040px;
  margin: 0 auto;

  @include g.mq(md) {
    padding: 0 20px;
  }
  @include g.mq(sm) {
    padding: 0 f.px2vw(20);
  }
}

.cmn-button01 {

  a {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: g.$col-green;
    font-weight: bold;

    @include g.mq(md) {
      font-size: 1.6rem;
    }
    @include g.mq(sm) {
      font-size: f.px2vw(16);
    }

    .icon {
      position: relative;
      background-color: g.$col-green;
      border: 2px solid g.$col-green;
      border-radius: 50%;
      transition: all .3s;

      @include g.mq(md) {
        width: 45px;
        height: 45px;
        margin-right: 12px;  
      }
      @include g.mq(sm) {
        width: f.px2vw(45);
        height: f.px2vw(45);
        margin-right: f.px2vw(12);  
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-image: url("../img/cmn/arrow-icon01.png");
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .3s;

        @include g.mq(md) {
          width: 17px;
          height: 8px;  
        }
        @include g.mq(sm) {
          width: f.px2vw(17);
          height: f.px2vw(8);  
        }
      }
    }

    &:hover {
      .icon {
        background-color: #fff;
        &::before {
          left: 60%;
          background-image: url("../img/cmn/arrow-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;  
        }
      }
    }
  }
}
.cmn-button02 {
  text-align: center;

  a,input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: g.$col-green;
    border: 2px solid g.$col-green;
    color: #fff;
    font-weight: bold;
    width: 100%;
    transition: all .3s;

    @include g.mq(md) {
      max-width: 339px;
      height: 58px;
      border-radius: 37px;
      font-size: 1.6rem;  
    }
    @include g.mq(sm) {
      height: f.px2vw(58);
      border-radius: f.px2vw(37);
      font-size: f.px2vw(16);  
    }

    &:hover {
      background-color: #fff;
      color: g.$col-green;
    }
  }
  
}
.contact-button01 {
  text-align: center;

  a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: g.$col-green;
    border: 2px solid g.$col-green;
    width: 100%;
    transition: all .3s;

    @include g.mq(md) {
      max-width: 286px;
      height: 58px;
      border-radius: 37px;    
    }
    @include g.mq(sm) {
      height: f.px2vw(58);
      border-radius: f.px2vw(37);    
    }

    p {
      position: relative;
      font-weight: bold;
      color: #fff;  
      transition: all .3s;

      @include g.mq(md) {
        padding-left: 30px;
        font-size: 1.6rem;  
      }
      @include g.mq(sm) {
        padding-left: f.px2vw(30);
        font-size: f.px2vw(16);  
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../img/cmn/contact-icon02.png");
        background-size: contain;
        background-repeat: no-repeat;
        left: 0;
        transition: all .3s;

        @include g.mq(md) {
          width: 25px;
          height: 17px;  
        }
        @include g.mq(sm) {
          width: f.px2vw(25);
          height: f.px2vw(17);  
        }
      }
    }

    &:hover {
      background-color: #fff;

      p {
        color: g.$col-green;

        &::before {
          background-image: url("../img/cmn/contact-icon01.png");
          background-size: contain;
          background-repeat: no-repeat;  
        }
      }
    }

  }
}
.tell {
  margin: 0 auto;

  @include g.mq(md) {
    max-width: 378px;
  }

  strong {
    text-align: center;
    display: block;
    
    @include g.mq(md) {
      font-size: 1.8rem;
      margin-bottom: 16px;
    }
    @include g.mq(sm) {
      letter-spacing: -.005em;      
      display: block;
      margin-bottom: f.px2vw(10);
      font-size: f.px2vw(16);
    }
  }

  .box {
    margin: 0 auto;

    @include g.mq(md) {
      max-width: 340px;
    }
    @include g.mq(sm) {
      max-width: f.px2vw(270);
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;  
    }

    .img {

      @include g.mq(md) {
        flex: 0 0 25px;
        margin-right: 6px;  
      }
      @include g.mq(sm) {
        flex: 0 0 f.px2vw(25);
        margin-right: f.px2vw(6);
      }
    }

    .message {
      flex: 0 1 100%;
      color: g.$col-green;

      .txt {
        font-weight: bold;
        letter-spacing: 0.05em;

        @include g.mq(md) {
          font-size: 1.6rem;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(14);
        }

        span {
          font-weight: 400;

          @include g.mq(md) {
            font-size: 1.4rem;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(12);
          }
        }
      }

      .number {
        line-height: 1;
        font-weight: 900;
        letter-spacing: 0.05em;

        @include g.mq(md) {
          font-size: 3.6rem;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(30);
        }
      }
    }
  }

  .tell-txt {
    color: g.$col-green;
    letter-spacing: 0.005em;
    text-align: right;
    font-weight: bold;

    @include g.mq(md) {
      font-size: 1.2rem;
      margin-right: 50px;  
    }
    @include g.mq(sm) {
      font-size: f.px2vw(12);
      margin-right: f.px2vw(50);  
    }
  }

  .date {
    text-align: center;
    background-color: rgba($color: g.$col-green, $alpha: .18);
    width: 100%;

    @include g.mq(md) {
      padding: 5px 0;
      margin-top: 5px;
      font-size: 1.2rem;  
      border-radius: 4px;
    }
    @include g.mq(sm) {
      width: 88%;
      margin: 0 auto;
      font-size: f.px2vw(12);
      margin-top: f.px2vw(10);
      border-radius: f.px2vw(4);
      padding: f.px2vw(6);
    }
  }
}
.tag-list {
  display: flex;
  flex-wrap: wrap;

  @include g.mq(md) {
    gap: 6px;
    margin-top: 6px;  
  }
  @include g.mq(sm) {
    margin-top: f.px2vw(14);
    gap: f.px2vw(6);
  }

  .tag-item {
    background-color: #F2F2F2;
    letter-spacing: 0.0025em;
    
    @include g.mq(md) {
      border-radius: 4px;
      font-size: 1.2rem;  
      padding: 4px 10px;
    }
    @include g.mq(sm) {
      font-size: f.px2vw(12);
      border-radius: f.px2vw(2);
      padding: f.px2vw(4) f.px2vw(10);
    }
  }
}
/*==============================================================
# 下層共通
==============================================================*/
.under {
  position: relative;
  overflow-x: clip;
  
  .under-title {
    width: 100%;

    @include g.mq(md) {
      background-image: url("../img/cmn/under-bg01.png");
      background-size: cover;
      background-repeat: no-repeat;  
      height: 215px;
      padding-top: 100px;  
    }
    @include g.mq(sm) {
      background-image: url("../img/cmn/sp/under-bg01.png");
      background-size: cover;
      background-repeat: no-repeat;  
      padding: f.px2vw(128) 0 f.px2vw(29);        
    }


    .content {

      @include g.mq(md) {
        max-width: 1380px;
        padding: 0 190px;  
      }
    }
  
    .title {
      font-weight: bold;
      letter-spacing: 0.1em;
      
      @include g.mq(md) {
        font-size: 3.2rem;
      }
      @include g.mq(sm) {
        line-height: 1.36;
        font-size: f.px2vw(28);
      }
  
      span {
        display: block;
        color: g.$col-green;
        letter-spacing: 0.1em;

        @include g.mq(md) {
          font-size: 1.6rem;
          margin-bottom: 10px;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(14);
        }
      }
    }
  }
  
  .bg {
    position: absolute;
    top: 0;
    width: 100vw;
  
    .icon {
      position: absolute;
      z-index: -1;
  
      &.icon01 {
        width: 637px;
        top: 765px;
        right: -354px;

        @include g.mq(sm) {
          top: f.px2vw(3302);
          right: f.px2vw(-420);
        }
      }
      &.icon02 {
        width: 779px;
        top: 1167px;
        left: -448px;
      }
      &.icon03 {
        width: 637px;
        top: 2747px;
        right: -354px;
      }
      &.icon04 {
        width: 779px;
        top: 3148px;
        left: -448px;
      }
    }
  }
}


/*==============================================================
# fadeIn
==============================================================*/
.fadeIn_up {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 500ms ease;
}
.fadeIn_up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
.fadeIn_up_repeat {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up_repeat.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
