@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
  footer
========================================================================== */
footer {
  position: relative;
  overflow-y: hidden;
  overflow-x: clip;
  z-index: 1;

  @include g.mq(md) {
    margin-top: 116px;
  }
  @include g.mq(sm) {
    padding-top: f.px2vw(112);
  }


  .footer-wrap {
    position: relative;


    .flex {
      position: relative;
      max-width: 1000px;
      margin: 0 auto;  

      @include g.mq(md) {
        display: flex;
        justify-content: space-between;
      }
      @include g.mq(sm) {
        padding: 0 f.px2vw(20);
      }
      .company {
        
        @include g.mq(md) {
          flex: 0 0 351px;
          margin-right: 140px;
        }
        @media screen and (min-width: 767px) and (max-width:1040px) {
          flex: 0 0 f.px2vw(351,1040);
          margin-right: f.px2vw(140,1040);
        }
      
        .logo {

          @include g.mq(md) {
            width: 176px;
            margin: 10px 0 18px;  
          }
          @media screen and (min-width: 767px) and (max-width:1040px) {
            width: f.px2vw(176,1040);
            margin: f.px2vw(10,1040) 0 f.px2vw(18,1040);
          }
          @include g.mq(sm) {
            width: f.px2vw(176);
          }
        }

        .company-txt {

          @include g.mq(md) {
            font-size: 1.3rem;
            margin-bottom: 20px;
          }
          @include g.mq(sm) {
            line-height: 1.38;
            font-size: f.px2vw(14);
            margin: f.px2vw(20) 0 f.px2vw(10);
          }
        }        
  
        dl {
          dt {
            font-weight: bold;
            color: g.$col-green;
            
            @include g.mq(md) {
              font-size: 1.6rem;
            }
            @include g.mq(sm) {
              line-height: 1.375;
              font-size: f.px2vw(16);
            }
          }
          dd {

            @include g.mq(md) {
              font-size: 1.3rem;
              line-height: 2.3;
            }
            @include g.mq(sm) {
              line-height: 1.38;
              font-size: f.px2vw(13);
            }
  
            .link {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              letter-spacing: 0.05em;
              color: #fff;
              background-color: g.$col-green;

              @include g.mq(md) {
                margin-left: 9px;
                font-size: 1.4rem;
                width: 47px;
                height: 23px;
                border-radius: 3px;  
              }
              @include g.mq(sm) {
                width: f.px2vw(43);
                height: f.px2vw(21);
                border-radius: f.px2vw(3);
                margin-left: f.px2vw(10);
                font-size: f.px2vw(12);
              }
            }
          }
          &+dl {

            @include g.mq(md) {
              margin-top: 21px;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(21);
            }
          }
        }
  
        .tell {

          @include g.mq(md) {
            margin-top: 27px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(20);
          }

          .number {
            letter-spacing: normal;
          }
        }

        .contact {

          @include g.mq(md) {
            margin-top: 20px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(20);
          }
        }
      }
  
      .list-content {

        @include g.mq(md) {
          flex: 0 1 100%;
          margin-top: 37px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(45);
        }
  
        .box {

          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
          }
          
          .list {
            @include g.mq(md) {
              margin-top: 9px;
            }

            &:first-child {
              @include g.mq(md) {
                margin-right: 25px;
              }
              @include g.mq(sm) {
                margin-top: 0;
              }
            }
  
            .item {
              position: relative;
              transition: all .3s;

              &:hover {
                color: g.$col-green;
              }

              &.comming {
                a {
                  color: #acacac;
                  pointer-events: none;
                }
              }

              a {
                position: relative;

                @include g.mq(md) {
                  display: inline-block;
                  line-height: 1.5;
                  font-size: 1.4rem;
                  padding: 4px 0 4px 20px;  

                  &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: g.$col-green;
                    width: 12px;
                    height: 2px;
                  } 
                }
                @include g.mq(sm) {
                  display: block;
                  font-weight: bold;
                  letter-spacing: 0.05em;
                  border-bottom: 2px dotted g.$col-green;
                  font-size: f.px2vw(16);
                  padding: f.px2vw(16) 0;

                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: f.px2vw(7) 0 f.px2vw(7) f.px2vw(8);
                    border-color: transparent transparent transparent g.$col-green;
                    border-radius: f.px2vw(10);
                  }
                }

                
              }

              &.tab {
                .link {
                  position: relative;

                  @include g.mq(md) {
                    display: inline-block;
                  }
                  @include g.mq(sm) {
                    display: block;
                  }
                  
                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url("../img/cmn/tab-icon01.png");
                    background-size: cover;
                    background-repeat: no-repeat;

                    @include g.mq(md) {
                      width: 13px;
                      height: 10px;
                      right: -20px;  
                    }
                    @include g.mq(sm) {
                      border-width: 0;
                      border-color: transparent;
                      border-radius: 0;  
                      width: f.px2vw(20);
                      height: f.px2vw(15);
                      right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .discussion-box {

          @include g.mq(md) {
            margin-top: 20px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(34);
          }

          a {
            display: flex;

            .icon {
              position: relative;
              background-color: g.$col-green;
              border-radius: 50%;
              border: 2px solid g.$col-green;
              transition: all .3s;

              @include g.mq(md) {
                flex: 0 0 45px;
                width: 45px;
                height: 45px;
                margin-right: 12px;  
              }
              @include g.mq(sm) {
                flex: 0 0 f.px2vw(45);
                width: f.px2vw(45);
                height: f.px2vw(45);
                margin-right: f.px2vw(12);  
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-image: url("../img/cmn/arrow-icon01.png");
                background-size: cover;
                background-repeat: no-repeat;
                transition: all .3s;

                @include g.mq(md) {
                  width: 17px;
                  height: 8px;  
                }
                @include g.mq(sm) {
                  width: f.px2vw(17);
                  height: f.px2vw(8);  
                }
              }
            }
            &:hover {
              .icon {
                background-color: #fff;
                &::before {
                  left: 60%;
                  background-image: url("../img/cmn/arrow-icon02.png");
                  background-size: cover;
                  background-repeat: no-repeat;  
                }
              }
            }
            .message {

              .title {
                color: g.$col-green;
                font-weight: bold;
                line-height: 1.2;

                @include g.mq(md) {
                  font-size: 2rem;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(20);
                }

                span {
                  font-weight: 400;
                  display: block;

                  @include g.mq(md) {
                    font-size: 1.3rem;
                  }
                  @include g.mq(sm) {
                    font-size: f.px2vw(12);
                  }
                }
              }
              .txt {
                line-height: 1.5;
                @include g.mq(md) {
                  font-size: 1.3rem;
                  margin-top: 5px;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  margin-top: f.px2vw(5);
                }
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    position: relative;
    width: 100%;

    @include g.mq(md) {
      height: 268px;
      margin-top: 13px;
    }
    @include g.mq(sm) {
      height: f.px2vw(175);
      margin-top: f.px2vw(30);
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;

      @include g.mq(md) {
        background-image: url("../img/cmn/footer-bg01.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 1972px;
      }
      @include g.mq(sm) {
        background-image: url("../img/cmn/sp/footer-bg01.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
      }
    }
    
    .txt {
      position: absolute;
      letter-spacing: 0.05em;
      width: 100%;
      text-align: center;

      @include g.mq(md) {
        font-size: 1.3rem;
        bottom: 30px;
      }
      @include g.mq(sm) {
        line-height: 1.428;
        font-size: f.px2vw(14);
        bottom: f.px2vw(35);
      }
    }
  }
}
