@use "../global" as g;
@use "../foundation" as f;

/* ==========================================================================
  base
========================================================================== */

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  color: g.$font-col;
  background-color: #fff;
  font-family: g.$font-round;
  font-weight: 400;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
}

.body-wrap {
  overflow-y: hidden;
}

h1,h2,h3,h4,h4,p,a,ul,li,dt,dd,span,strong,small {
  transform: rotate(0.03deg);
}


a,
button {
  color: inherit;
  cursor: pointer;
}

img {
  max-width: 100%;
  width: 100%;
}

em {
  font-style: normal;
}

button {
  outline: none;
  border: 0;
  background-color: transparent;
}