@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
event
========================================================================== */
#event {

  .event-wrap {

    @include g.mq(md) {
      margin-top: 80px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(50);
      padding: 0 f.px2vw(20);
    }

    .list {

      @include g.mq(md) {
        display: flex;
        flex-wrap: wrap;
        gap: 66px 27px;  
      }
      @media screen and (min-width:767px) and (max-width: 1040px) {
        gap: 66px f.px2vw(27,1040);
      }

      .item {

        @include g.mq(md) {
          width: 31.5%;
        }
        @include g.mq(sm) {
          &+.item {
            margin-top: f.px2vw(40);
          }
        }

        &:hover {
          img {
            opacity: .7;
            transform: scale3d(1.1, 1.1, 1.1);              
          }
        }

        .img {
          overflow: hidden;

          @include g.mq(md) {
            border-radius: 10px;
          }
          @include g.mq(sm) {
            border-radius: f.px2vw(10);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.5s ease;

            @include g.mq(md) {
              border-radius: 10px;
            }
            @include g.mq(sm) {
              border-radius: f.px2vw(10);
            }
          }
        }
        .mesaage {

          @include g.mq(md) {
            margin-top: 10px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(10);
          }

          .title {
            font-weight: bold;
            line-height: 1.3;

            @include g.mq(md) {
              font-size: 1.8rem;
            }
            @include g.mq(sm) {
              font-size: f.px2vw(18);
            }
          }

          .tag-list {
            @include g.mq(md) {
              margin-top: 6px;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(6);
            }

            .tag-item {
              background-color: #fff;
              border: 1px solid #414141;
            }
          }
        }
      }
    }

    .pagenation {
      display: flex;
      align-items: center;
      justify-content: center;

      @include g.mq(md) {
        margin-top: 50px;
        column-gap: 28px;  
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(40);
        column-gap: f.px2vw(20);
      }

      .item {
        border-radius: 50%;
        border: 1px solid g.$col-green;
        color: g.$col-green;
        font-weight: bold;

        @include g.mq(md) {
          width: 40px;
          height: 40px;
          font-size: 2rem;  
        }
        @include g.mq(sm) {
          width: f.px2vw(30);
          height: f.px2vw(30);
          font-size: f.px2vw(15);
        }

        &.active {
          background-color: rgba($color: g.$col-green, $alpha: .1);
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;  
          width: 100%;
          height: 100%;
        }
      }
      .arrow {
        cursor: pointer;
        position: relative;
        background-color: g.$col-green;
        border: 2px solid g.$col-green;
        border-radius: 50%;
        transition: all .3s;

        @include g.mq(md) {
          width: 31px;
          height: 31px;  
        }
        @include g.mq(sm) {
          width: f.px2vw(25);
          height: f.px2vw(25);  
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          transition: all .3s;
          background-size: contain;
          background-repeat: no-repeat;

          @include g.mq(md) {
            width: 12px;
            height: 6px;  
          }
          @include g.mq(sm) {
            width: f.px2vw(9);
            height: f.px2vw(7);
          }
        }

        &:hover {
          background-color: #fff;
        }

        &.prev {
          &::before {
            background-image: url("../img/cmn/arrow-icon05.png");
          }
          &:hover {
            &::before {
              background-image: url("../img/cmn/arrow-icon03.png");
            }
          }
        }
        &.next {
          &::before {
            background-image: url("../img/cmn/arrow-icon04.png");
          }
          &:hover {
            &::before {
              background-image: url("../img/cmn/arrow-icon02.png");
            }
          }
        }
      }
    }
  }
}

#event-single {

  img {
    width: auto;
    height: auto;
  }

  .single-wrap {

    @include g.mq(md) {
      margin-top: 80px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(40);
    }

    .head {

      .mv  {
        max-width: 800px;
        margin: 0 auto;

        img {
          @include g.mq(md) {
            border-radius: 10px;
          }
          @include g.mq(sm) {
            border-radius: f.px2vw(10);
          }
        }
      }

      .tag-list {

        @include g.mq(md) {
          margin-top: 40px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(18);
        }

        .tag-item {
          background-color: #fff;
          border: 1px solid #414141;
        }
      }

      .head-title {
        font-weight: bold;
        border-bottom: 1px solid g.$col-green;

        @include g.mq(md) {
          line-height: 1.3;
          font-size: 3rem;
          padding-bottom: 13px;
          margin-top: 14px;  
        }
        @include g.mq(sm) {
          line-height: 1.36;
          font-size: f.px2vw(22);
          padding-bottom: f.px2vw(8);
        }
      }
      .head-txt {

        @include g.mq(md) {
          line-height: 2;
          margin-top: 16px;
          font-size: 1.6rem;  
        }
        @include g.mq(sm) {
          line-height: 1.625;
          font-size: f.px2vw(16);
          margin-top: f.px2vw(10);
        }
      }
      .head-img {

        @include g.mq(md) {
          display: flex;
          justify-content: center;
          column-gap: 20px;
          margin-top: 25px;  
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(20);
          padding: 0 f.px2vw(20);
        }

        .img {

          @include g.mq(md) {
            width: 31.6%;
          }
          @include g.mq(sm) {
            &+.img {
              margin-top: f.px2vw(15);
            }
          }
        }
      }
      .head-box {
        background-color: #FBF9F7;

        @include g.mq(md) {
          display: flex;
          border-radius: 20px;
          padding: 30px 50px;
          margin-top: 40px;  
        }
        @include g.mq(sm) {
          padding: f.px2vw(20) f.px2vw(15);
          border-radius: f.px2vw(20);
          margin-top: f.px2vw(28);
        }

        .message {
          
          @include g.mq(md) {
            flex: 0 1 100%;
          }

          dl {
            display: flex;
            align-items: flex-start;
            
            @include g.mq(md) {
              line-height: 1.5;
              font-size: 1.6rem;
            }
            @include g.mq(sm) {
              line-height: 1.375;
              font-size: f.px2vw(16);
            }
  
            &+dl {

              @include g.mq(md) {
                margin-top: 20px;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(18);
              }
            }
  
            dt {
              display: flex;
              align-items: center;
              font-weight: bold;

              @include g.mq(md) {
                flex: 0 0 118px;
                font-size: 1.6rem;
              }
              @include g.mq(sm) {
                flex: 0 0 f.px2vw(106);
                font-size: f.px2vw(16);
              }
  
              .icon {

                @include g.mq(md) {
                  flex: 0 0 20px;
                  margin-right: 10px;  
                }
                @include g.mq(sm) {
                  flex: 0 0 f.px2vw(20);
                  margin-right: f.px2vw(10);  
                }
              }
            }
            dd {
              flex: 0 1 100%;
  
              a {
                position: relative;
                color: g.$col-green;
                text-decoration: underline;
  
                &::after {
                  content: "";
                  position: absolute;
                  top: 55%;
                  transform: translateY(-50%);
                  right: -15px;
                  background-image: url("../img/cmn/tab-icon02.png");
                  background-size: contain;
                  background-repeat: no-repeat;

                  @include g.mq(md) {
                    width: 12px;
                    height: 12px;  
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(12);
                    height: f.px2vw(12);  
                  }
                }
              }
            }
          }
        }

        .img {

          @include g.mq(md) {
            flex: 0 0 220px;
            margin-left: 133px;  
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(16);
          }

          .caption {
            display: block;

            @include g.mq(md) {
              font-size: 1.4rem;
              margin-top: 10px;
            }
            @include g.mq(sm) {
              font-size: f.px2vw(16);
              margin-top: f.px2vw(10);
            }
          }
        }

      }

      .button {
        @include g.mq(md) {
          margin-top: 24px; 
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(24);
          padding: 0 f.px2vw(20);
        }

        a {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width:0;
            height:0;
            border-style:solid;
            border-color: #FFFFFF transparent transparent transparent;
            transition: all .3s;

            @include g.mq(md) {
              right: 20px;
              border-radius: 10px;
              border-width: 8px 7px 0 7px;
            }
            @include g.mq(sm) {
              right: f.px2vw(20);
              border-radius: f.px2vw(10);
              border-width: f.px2vw(8) f.px2vw(7) 0 f.px2vw(7);
            }
          }

          &:hover {
            &::after {
              border-color: g.$col-green transparent transparent transparent;
            }
          }
        }
      }

      .back-button {
        text-align: right;

        @include g.mq(md) {
          margin-top: 30px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(30);
          padding-right: f.px2vw(20);
        }
      }
    }

    .body {

      @include g.mq(md) {
        margin-top: 140px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(100);
      }

      .body-title {
        text-align: center;
        font-weight: bold;
        margin: 0 calc(100% - 100vw);
        border-bottom: 1px solid g.$col-green;

        @include g.mq(md) {
          font-size: 3.6rem;
          padding-bottom: 40px;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(28);
          padding-bottom: f.px2vw(15);
        }

        span {
          display: block;
          color: g.$col-green;

          @include g.mq(md) {
            font-size: 2.4rem;
            margin-bottom: 8px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(16);
            margin-bottom: f.px2vw(10);
          }
        }
      }

      .tell {

        @include g.mq(md) {
          margin-top: 68px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(30);
        }
      }
    }
  }
}