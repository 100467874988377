/* ==========================================================================
    variables
   ========================================================================== */

// font
$font-round: 'M PLUS Rounded 1c', sans-serif;

// color
$col-black: #000;
$col-yellow: #FFBA00;
$col-cyan: #60BBD6;
$col-purple: #EB9EDA;
$col-blue: #8CACBA;
$col-green: #4CB57B;
$col-red: #cf0f31;

$font-col: #414141;


