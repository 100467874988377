@use "../global" as g;

/* ==========================================================================
  utility
  ========================================================================== */
/* ---------------------------------------------------------------------------
  show/hide
  --------------------------------------------------------------------------- */
// mobile
.sp {
  display: block;

  @include g.mq(md) {
    display: none;
  };
}
.sp-flex {
  display: flex !important;

  @include g.mq(md) {
    display: none !important;
  };
}

// desktop
.pc {
  display: none;

  @include g.mq(md) {
    display: block;
  };
}
.pc-flex {
  display: none !important;

  @include g.mq(md) {
    display: flex !important;
  };
}


