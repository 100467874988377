@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
philosophy
========================================================================== */
#philosophy {

  .philosophy-wrap {

    @include g.mq(md) {
      margin-top: 48px;
    }
    @include g.mq(sm) {
      margin-top: f.px2vw(30);
      padding: 0 f.px2vw(20); 
    }

    .box {
      position: relative;

      .img-content {
        position: relative;
        max-width: 1360px;
        margin: 0 auto;

        .img {
  
          @include g.mq(md) {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            width: 730px;
          }
          @media screen and (min-width:767px) and (max-width: 1200px) {
            width: f.px2vw(730,1200);
          }
          @include g.mq(sm) {
            margin-right: calc(50% - 50vw);
          }
        }
      }



      .message {

        @include g.mq(md) {
          padding-top: 110px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(35);
        }

        .title {
          font-weight: bold;
          letter-spacing: 0.1em;

          @include g.mq(md) {
            line-height: 1.15;
            font-size: 4rem;  
          }
          @include g.mq(sm) {
            line-height: 1.27;
            font-size: f.px2vw(22);
          }
        }
        .txt {

          @include g.mq(md) {
            line-height: 2;
            font-size: 1.6rem;
            margin-top: 58px;  
          }
          @include g.mq(sm) {
            line-height: 1.86;
            font-size: f.px2vw(14);
            margin-top: f.px2vw(24);
          }
        }
      }

    }
    .logo {

      @include g.mq(md) {
        width: 707px;
        margin-top: 76px;  
      }
      @media screen and (min-width:767px) and (max-width: 1040px) {
        width: f.px2vw(707,1400);
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(40);
        width: f.px2vw(256);
      }
    }
  }
  .bg {
    position: absolute;
    top: 0;
    width: 100vw;
  
    .icon {
      position: absolute;
      z-index: -1;
  
      &.icon01 {
        width: 637px;
        top: 867px;
        right: -284px;

        @include g.mq(sm) {
          top: f.px2vw(1402);
          right: f.px2vw(-421);
        }
      }
      &.icon02 {
        width: 788px;
        transform: rotate(220deg);
        top: 489px;
        left: -532px;

        @include g.mq(sm) {
          display: none;
        }
      }
    }
  }
}
